import { gql } from "@apollo/client"

export const GET_SEARCH_RESULTS = gql`
  query SearchQuery($ids: [ID]!, $afterProducts: String!) {
    products(
      first: 25
      filter: { isPublished: true, ids: $ids }
      after: $afterProducts
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          slug
          variants {
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
          media{
            url
            alt
          }
          thumbnail(size: 512) {
            url
            alt
          }
          seoTitle
          seoDescription
          availableForPurchase
          isAvailableForPurchase
          pricing {
            onSale
            discount {
              net {
                amount
                currency
              }
            }
            priceRangeUndiscounted {
              start {
                gross {
                  amount
                }
              }
            }
            priceRange {
              start {
                gross {
                  amount
                  currency
                }
              }
            }
          }
          attributes {
            values {
              name
              slug
            }
            attribute {
              name
              slug
            }
          }
        }
      }
    }
  }
`
