import { useLazyQuery } from "@apollo/client"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFlexSearch } from "react-use-flexsearch"
import { clearAnchorAll } from "../../../entities/anchorListitem/anchorListItem.actions"
import { setSearchKeyList } from "../../../entities/searchKey/searchKey.actions"
import { ProductsView } from "../TemplateProducts/ProductsFiltered/Products.view"
import { getSearchFromUrl } from "./../../../utils/url"
import { GET_SEARCH_RESULTS } from "./PageRecherche.queries"



const getProductsFromData = data =>
  data.products.edges.map(product => product.node)

const getPageInfoFromData = data => ({
  endCursor: data.products.pageInfo.endCursor,
  hasNextPage: data.products.pageInfo.hasNextPage,
})

export function PageRechercheContainer() {
  const { keyword: search } = useSelector(state => state.searchKeyReducer)
  const [getProducts, dynamicGetProducts] = useLazyQuery(GET_SEARCH_RESULTS)
  const [products, setProducts] = useState([])
  const [pageInfo, setPageInfo] = useState({
    endCursor: "",
    hasNextPage: false,
  })
  const [totalCount, setTotalCount] = useState(0)

  const dispatch = useDispatch()


  const isLoadingMore = useRef(false)

  // Flex search engine intialization
  const queryFlexSearchEngineData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)
  const index = queryFlexSearchEngineData.localSearchPages.index
  const store = queryFlexSearchEngineData.localSearchPages.store
  const customSearch = useFlexSearch(search, index, store)
  const ids = useRef([])

  const getQueryVariables = () => {
    return {
      variables: {
        ids: ids.current,
        afterProducts: pageInfo.endCursor,
      },
    }

  }

  useEffect(() => {
    setProducts([])
    dispatch(clearAnchorAll())
    setTotalCount(0)
    isLoadingMore.current = false
    // TODO : probleme de recherche
    if (getSearchFromUrl().length >= 3 && customSearch) {
      ids.current = customSearch.map(r => r.id)
      getProducts(getQueryVariables())
    }

  }, [customSearch, search, window.location.search])

  useEffect(() => {
    if (dynamicGetProducts.data) {
      let resProducts = getProductsFromData(dynamicGetProducts.data)
      const newProducts = resProducts.map((item) => [item.id, item])

      const result = [...products, ...new Map(newProducts)]
      setProducts(result)
      // let resPageInfo = getPageInfoFromData(dynamicGetProducts.data)
      // setPageInfo(resPageInfo)
      setTotalCount(dynamicGetProducts.data.products.totalCount)
    }
  }, [dynamicGetProducts])

  const onLoadMore = async () => {
    isLoadingMore.current = true
    await getProducts(getQueryVariables())
  }

  return (
    <ProductsView
      products={products}
      onLoadMore={onLoadMore}
      loading={dynamicGetProducts.loading}
      error={dynamicGetProducts.error}
      totalCount={totalCount}
      categoryName="Recherche"
      onClearFilter={undefined} filters={[]}
      onFiltersChange={undefined}
      description={""}
      contenuSEO={""}
      liens_haut_page={""}
      liens_bas_page={""}
      faq={{
        question: "",
        reponse: ""
      }}
      seoTitle={""}
      seoDescription={""}
      titre_lien_bas_page={""}
      category={undefined} />
  )
}
